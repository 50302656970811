/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from 'layouts/dashboards/analytics'
import Sales from 'layouts/dashboards/sales'
import ProfileOverview from 'layouts/pages/profile/profile-overview'
import AllProjects from 'layouts/pages/profile/all-projects'
import NewUser from 'layouts/pages/users/new-user'
import Settings from 'layouts/pages/account/settings'
import Billing from 'layouts/pages/account/billing'
import Invoice from 'layouts/pages/account/invoice'
import Timeline from 'layouts/pages/projects/timeline'
import PricingPage from 'layouts/pages/pricing-page'
import Widgets from 'layouts/pages/widgets'
import RTL from 'layouts/pages/rtl'
import Charts from 'layouts/pages/charts'
import Notifications from 'layouts/pages/notifications'
import Kanban from 'layouts/applications/kanban'
import Wizard from 'layouts/applications/wizard'
import DataTables from 'layouts/applications/data-tables'
import Calendar from 'layouts/applications/calendar'
import NewProduct from 'layouts/ecommerce/products/new-product'
import EditProduct from 'layouts/ecommerce/products/edit-product'
import ProductPage from 'layouts/ecommerce/products/product-page'
import OrderList from 'layouts/ecommerce/orders/order-list'
import OrderDetails from 'layouts/ecommerce/orders/order-details'
import SignInBasic from 'layouts/authentication/sign-in/basic'
import SignInCover from 'layouts/authentication/sign-in/cover'
import SignInIllustration from 'layouts/authentication/sign-in/illustration'
import SignUpCover from 'layouts/authentication/sign-up/cover'
import ResetCover from 'layouts/authentication/reset-password/cover'

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar'

// @mui icons
import Icon from '@mui/material/Icon'

// Images
import profilePicture from 'assets/images/avatar.png'
// ZeroUno
import { config } from 'variables/Config.js'
import authService from 'services/auth.service'
import LoginPage from 'views/auth/login.js'
import MainDashboard from 'views/dashboard'
import UserProfile01 from 'views/users/UserProfile.js'
import UsersTable from 'views/users/UsersTable.js'
import UserForm from 'views/users/UserForm.js'
import EnumerationTable from 'views/enumerations/EnumerationTable.js'
import EnumerationForm from 'views/enumerations/EnumerationForm.js'
import CompanyTable from 'views/crm/CompanyTable.js'
import CompanyForm from 'views/crm/CompanyForm.js'
import CustomerTable from 'views/crm/CustomerTable.js'
import CustomerForm from 'views/crm/CustomerForm.js'
import AddressTable from 'views/crm/AddressTable.js'
import AddressForm from 'views/crm/AddressForm.js'
import AgentTable from 'views/crm/AgentTable.js'
import AgentForm from 'views/crm/AgentForm.js'
import DoctorTable from 'views/crm/DoctorTable.js'
import DoctorForm from 'views/crm/DoctorForm.js'
import PathologyTable from 'views/crm/PathologyTable.js'
import PathologyForm from 'views/crm/PathologyForm.js'
import ProductTable from 'views/crm/ProductTable.js'
import ProductForm from 'views/crm/ProductForm.js'
import ProductModelTable from 'views/crm/ProductModelTable.js'
import ProductModelForm from 'views/crm/ProductModelForm.js'
import OrderTable from 'views/crm/OrderTable.js'
import OrderForm from 'views/crm/OrderForm.js'
import Statistics from 'views/crm/Statistics.js'
import TransactionTable from 'views/crm/TransactionTable.js'
import TransactionForm from 'views/crm/TransactionForm.js'

const loggedUser = authService.user()

const routes = [
  {
    type: 'collapse',
    name: loggedUser ? loggedUser.name + ' ' + loggedUser.surname : '',
    key: 'loggedUser',
    icon: <MDAvatar src={loggedUser && loggedUser.image ? config.SERVER_URL + '/download/' + loggedUser.image : profilePicture} alt='imgProfile' size='sm' />,
    collapse: [
      {
        name: 'Il Mio Profilo',
        key: 'il-mio-profilo',
        component: <UserProfile01 />,
        route: '/myprofile',
        invisible: false
      },
      {
        name: 'My Profile',
        key: 'my-profile',
        route: '/pages/profile/profile-overview',
        component: <ProfileOverview />,
        invisible: !config.SAMPLE_DATA
      },
      {
        name: 'Settings',
        key: 'profile-settings',
        route: '/pages/account/settings',
        component: <Settings />,
        invisible: !config.SAMPLE_DATA
      },
      {
        name: 'Logout',
        key: 'logout',
        route: '/login',
        component: <LoginPage />,
        invisible: false
      }
    ]
  },
  { type: 'divider', key: 'divider-0' },
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    icon: <Icon fontSize='medium'>dashboard</Icon>,
    collapse: [
      {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <Icon fontSize='medium'>dashboard</Icon>,
        route: '/dashboards/main',
        component: <MainDashboard />
      },
      {
        name: 'Analytics',
        key: 'analytics',
        route: '/dashboards/analytics',
        component: <Analytics />,
        invisible: !config.SAMPLE_DATA
      },
      {
        name: 'Sales',
        key: 'sales',
        route: '/dashboards/sales',
        component: <Sales />,
        invisible: !config.SAMPLE_DATA
      }
    ]
  },
  {
    type: 'collapse',
    name: 'CRM',
    key: 'crm',
    icon: <Icon fontSize='medium'>apps</Icon>,
    collapse: [
      {
        name: 'Utente',
        key: 'user',
        route: '/users/:idUser',
        component: <UserForm />,
        invisible: true
      },
      {
        name: 'Utenti',
        key: 'users',
        route: '/users',
        component: <UsersTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Azienda',
        key: 'company',
        route: '/companies/:idCompany',
        component: <CompanyForm />,
        invisible: true
      },
      {
        name: 'Aziende',
        key: 'companies',
        route: '/companies',
        component: <CompanyTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Cliente',
        key: 'customer',
        route: '/customers/:idObj',
        component: <CustomerForm />,
        invisible: true
      },
      {
        name: 'Clienti',
        key: 'customers',
        route: '/customers',
        component: <CustomerTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Statistiche',
        key: 'statistics',
        route: '/stats',
        component: <Statistics />,
        invisible: !authService.isAdmin()
      },
      // {
      //   name: 'Indirizzo',
      //   key: 'address',
      //   route: '/customers/:idCustomer/addresses/:idObj',
      //   component: <AddressForm />,
      //   invisible: true
      // },
      // {
      //   name: 'Indirizzi',
      //   key: 'adresses',
      //   route: '/customers/:idCustomer/addresses',
      //   component: <AddressTable />,
      //   invisible: true
      // },
      {
        name: 'Ordine',
        key: 'order',
        route: '/customers/:idCustomer/orders/:idObj',
        component: <OrderForm />,
        invisible: true
      },
      {
        name: 'Ordini Cliente',
        key: 'customerOrders',
        route: '/customers/:idCustomer/orders',
        component: <OrderTable />,
        invisible: true
      },
      {
        name: 'Ordini',
        key: 'orders',
        route: '/orders',
        component: <OrderTable />,
        invisible: false
      },
      {
        name: 'Pagamento',
        key: 'payment',
        route: '/customers/:idCustomer/payments/:idObj',
        component: <TransactionForm />,
        invisible: true
      },
      {
        name: 'Pagamenti Cliente',
        key: 'customerPayments',
        route: '/customers/:idCustomer/payments',
        component: <TransactionTable />,
        invisible: true
      },
      {
        name: 'Pagamenti',
        key: 'payments',
        route: '/payments',
        component: <TransactionTable />,
        invisible: false
      },
      {
        name: 'Agente',
        key: 'agent',
        route: '/agents/:idObj',
        component: <AgentForm />,
        invisible: true
      },
      {
        name: 'Agenti',
        key: 'agents',
        route: '/agents',
        component: <AgentTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Medico',
        key: 'doctor',
        route: '/doctors/:idObj',
        component: <DoctorForm />,
        invisible: true
      },
      {
        name: 'Medici',
        key: 'doctors',
        route: '/doctors',
        component: <DoctorTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Patologia',
        key: 'pathology',
        route: '/pathologies/:idObj',
        component: <PathologyForm />,
        invisible: true
      },
      {
        name: 'Patologie',
        key: 'pathologies',
        route: '/pathologies',
        component: <PathologyTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Modello Prodotto',
        key: 'productModel',
        route: '/productModels/:idObj',
        component: <ProductModelForm />,
        invisible: true
      },
      {
        name: 'Modelli Prodotto',
        key: 'productModels',
        route: '/productModels',
        component: <ProductModelTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Prodotto',
        key: 'product',
        route: '/products/:idObj',
        component: <ProductForm />,
        invisible: true
      },
      {
        name: 'Prodotti',
        key: 'products',
        route: '/products',
        component: <ProductTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Descrizioni',
        key: 'enumerations',
        route: '/enumerations',
        component: <EnumerationTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Descrizione',
        key: 'enumeration',
        route: '/enumerations/:idObj',
        component: <EnumerationForm />,
        invisible: true
      }
    ]
  },
  {
    type: 'title',
    title: 'Pages',
    key: 'title-pages',
    invisible: !config.SAMPLE_DATA
  },
  {
    type: 'collapse',
    name: 'Pages',
    key: 'pages',
    icon: <Icon fontSize='medium'>image</Icon>,
    invisible: !config.SAMPLE_DATA,
    collapse: [
      {
        name: 'Profile',
        key: 'profile',
        collapse: [
          {
            name: 'Profile Overview',
            key: 'profile-overview',
            route: '/pages/profile/profile-overview',
            component: <ProfileOverview />
          },
          {
            name: 'All Projects',
            key: 'all-projects',
            route: '/pages/profile/all-projects',
            component: <AllProjects />
          }
        ]
      },
      {
        name: 'Users',
        key: 'users',
        collapse: [
          {
            name: 'New User',
            key: 'new-user',
            route: '/pages/users/new-user',
            component: <NewUser />
          }
        ]
      },
      {
        name: 'Account',
        key: 'account',
        collapse: [
          {
            name: 'Settings',
            key: 'settings',
            route: '/pages/account/settings',
            component: <Settings />
          },
          {
            name: 'Billing',
            key: 'billing',
            route: '/pages/account/billing',
            component: <Billing />
          },
          {
            name: 'Invoice',
            key: 'invoice',
            route: '/pages/account/invoice',
            component: <Invoice />
          }
        ]
      },
      {
        name: 'Projects',
        key: 'projects',
        collapse: [
          {
            name: 'Timeline',
            key: 'timeline',
            route: '/pages/projects/timeline',
            component: <Timeline />
          }
        ]
      },
      {
        name: 'Pricing Page',
        key: 'pricing-page',
        route: '/pages/pricing-page',
        component: <PricingPage />
      },
      { name: 'RTL', key: 'rtl', route: '/pages/rtl', component: <RTL /> },
      { name: 'Widgets', key: 'widgets', route: '/pages/widgets', component: <Widgets /> },
      { name: 'Charts', key: 'charts', route: '/pages/charts', component: <Charts /> },
      {
        name: 'Notfications',
        key: 'notifications',
        route: '/pages/notifications',
        component: <Notifications />
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Applications',
    key: 'applications',
    icon: <Icon fontSize='medium'>apps</Icon>,
    invisible: !config.SAMPLE_DATA,
    collapse: [
      {
        name: 'Kanban',
        key: 'kanban',
        route: '/applications/kanban',
        component: <Kanban />
      },
      {
        name: 'Wizard',
        key: 'wizard',
        route: '/applications/wizard',
        component: <Wizard />
      },
      {
        name: 'Data Tables',
        key: 'data-tables',
        route: '/applications/data-tables',
        component: <DataTables />
      },
      {
        name: 'Calendar',
        key: 'calendar',
        route: '/applications/calendar',
        component: <Calendar />
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Ecommerce',
    key: 'ecommerce',
    icon: <Icon fontSize='medium'>shopping_basket</Icon>,
    invisible: !config.SAMPLE_DATA,
    collapse: [
      {
        name: 'Products',
        key: 'products',
        collapse: [
          {
            name: 'New Product',
            key: 'new-product',
            route: '/ecommerce/products/new-product',
            component: <NewProduct />
          },
          {
            name: 'Edit Product',
            key: 'edit-product',
            route: '/ecommerce/products/edit-product',
            component: <EditProduct />
          },
          {
            name: 'Product Page',
            key: 'product-page',
            route: '/ecommerce/products/product-page',
            component: <ProductPage />
          }
        ]
      },
      {
        name: 'Orders',
        key: 'orders',
        collapse: [
          {
            name: 'Order List',
            key: 'order-list',
            route: '/ecommerce/orders/order-list',
            component: <OrderList />
          },
          {
            name: 'Order Details',
            key: 'order-details',
            route: '/ecommerce/orders/order-details',
            component: <OrderDetails />
          }
        ]
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Authentication',
    key: 'authentication',
    icon: <Icon fontSize='medium'>content_paste</Icon>,
    invisible: !config.SAMPLE_DATA,
    collapse: [
      {
        name: 'Sign In',
        key: 'sign-in',
        collapse: [
          {
            name: 'Basic',
            key: 'basic',
            route: '/authentication/sign-in/basic',
            component: <SignInBasic />
          },
          {
            name: 'Cover',
            key: 'cover',
            route: '/authentication/sign-in/cover',
            component: <SignInCover />
          },
          {
            name: 'Illustration',
            key: 'illustration',
            route: '/authentication/sign-in/illustration',
            component: <SignInIllustration />
          }
        ]
      },
      {
        name: 'Sign Up',
        key: 'sign-up',
        collapse: [
          {
            name: 'Cover',
            key: 'cover',
            route: '/authentication/sign-up/cover',
            component: <SignUpCover />
          }
        ]
      },
      {
        name: 'Reset Password',
        key: 'reset-password',
        collapse: [
          {
            name: 'Cover',
            key: 'cover',
            route: '/authentication/reset-password/cover',
            component: <ResetCover />
          }
        ]
      }
    ]
  },
  { type: 'divider', key: 'divider-1' },
  {
    type: 'title',
    title: 'Docs',
    key: 'title-docs',
    invisible: !config.SAMPLE_DATA
  },
  {
    type: 'collapse',
    name: 'Basic',
    key: 'basic',
    icon: <Icon fontSize='medium'>upcoming</Icon>,
    invisible: !config.SAMPLE_DATA,
    collapse: [
      {
        name: 'Getting Started',
        key: 'getting-started',
        collapse: [
          {
            name: 'Overview',
            key: 'overview',
            href: 'https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/'
          },
          {
            name: 'License',
            key: 'license',
            href: 'https://www.creative-tim.com/learning-lab/react/license/material-dashboard/'
          },
          {
            name: 'Quick Start',
            key: 'quick-start',
            href: 'https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/'
          },
          {
            name: 'Build Tools',
            key: 'build-tools',
            href: 'https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/'
          }
        ]
      },
      {
        name: 'Foundation',
        key: 'foundation',
        collapse: [
          {
            name: 'Colors',
            key: 'colors',
            href: 'https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/'
          },
          {
            name: 'Grid',
            key: 'grid',
            href: 'https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/'
          },
          {
            name: 'Typography',
            key: 'base-typography',
            href: 'https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/'
          },
          {
            name: 'Borders',
            key: 'borders',
            href: 'https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/'
          },
          {
            name: 'Box Shadows',
            key: 'box-shadows',
            href: 'https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/'
          },
          {
            name: 'Functions',
            key: 'functions',
            href: 'https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/'
          },
          {
            name: 'Routing System',
            key: 'routing-system',
            href: 'https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/'
          }
        ]
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Components',
    key: 'components',
    icon: <Icon fontSize='medium'>view_in_ar</Icon>,
    invisible: !config.SAMPLE_DATA,
    collapse: [
      {
        name: 'Alerts',
        key: 'alerts',
        href: 'https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/'
      },
      {
        name: 'Avatar',
        key: 'avatar',
        href: 'https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/'
      },
      {
        name: 'Badge',
        key: 'badge',
        href: 'https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/'
      },
      {
        name: 'Badge Dot',
        key: 'badge-dot',
        href: 'https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/'
      },
      {
        name: 'Box',
        key: 'box',
        href: 'https://www.creative-tim.com/learning-lab/react/box/material-dashboard/'
      },
      {
        name: 'Buttons',
        key: 'buttons',
        href: 'https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/'
      },
      {
        name: 'Date Picker',
        key: 'date-picker',
        href: 'https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/'
      },
      {
        name: 'Dropzone',
        key: 'dropzone',
        href: 'https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/'
      },
      {
        name: 'Editor',
        key: 'editor',
        href: 'https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/'
      },
      {
        name: 'Input',
        key: 'input',
        href: 'https://www.creative-tim.com/learning-lab/react/input/material-dashboard/'
      },
      {
        name: 'Pagination',
        key: 'pagination',
        href: 'https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/'
      },
      {
        name: 'Progress',
        key: 'progress',
        href: 'https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/'
      },
      {
        name: 'Snackbar',
        key: 'snackbar',
        href: 'https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/'
      },
      {
        name: 'Social Button',
        key: 'social-button',
        href: 'https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/'
      },
      {
        name: 'Typography',
        key: 'typography',
        href: 'https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/'
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Change Log',
    key: 'changelog',
    href: 'https://github.com/creativetimofficial/ct-material-dashboard/-pro-material-ui/blob/main/CHANGELOG.md',
    icon: <Icon fontSize='medium'>receipt_long</Icon>,
    invisible: !config.SAMPLE_DATA,
    noCollapse: true
  },
  {
    type: 'collapse',
    name: '01Rabbit',
    key: '01Rabbit',
    href: 'https://www.01rabbit.it',
    icon: <Icon fontSize='medium'>engineering</Icon>,
    noCollapse: true
  }
]

export default routes
