/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from 'layouts/pages/users/new-user/schemas/form'

const {
  formField: {
    firstName,
    lastName,
    company,
    email,
    password,
    repeatPassword,
    address1,
    address2,
    city,
    zip,
    twitter,
    facebook,
    instagram,
    publicEmail,
    bio
  }
} = checkout

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [company.name]: '',
  [email.name]: '',
  [password.name]: '',
  [repeatPassword.name]: '',
  [address1.name]: '',
  [address2.name]: '',
  [city.name]: '',
  [zip.name]: '',
  [twitter.name]: '',
  [facebook.name]: '',
  [instagram.name]: '',
  [publicEmail.name]: '',
  [bio.name]: ''
}
