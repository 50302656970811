'use strict'

import { useEffect, useContext, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import customerService from 'services/customer.service'
import orderService from 'services/order.service'
import transactionService from 'services/transaction.service'
import enumerationService from 'services/enumeration.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'

export default function TransactionForm () {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [obj, setObj] = useState({})
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState([])
  // const [enums, setEnums] = useState([])
  const [customers, setCustomers] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, watch, control, getValues, setValue, reset } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const { data: enums, isLoading: loadEnum, error: errEnums } = enumerationService.findByType('account')
  const orderId = new URLSearchParams(useLocation().search).get('orderId')

  const customerId = watch('customerId')

  useEffect(() => {
    // fetchAccessors(orderService, setOrders)
    fetchCustomers()
    // fetchEnums(enumerationService, setEnums, 'account')
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
    setValue('dateTransaction', DateTime.fromISO(obj.dateTransaction).toISODate())
    setValue('dateInvoice', DateTime.fromISO(obj.dateInvoice).toISODate())
  }, [obj])

  useEffect(() => {
    fetchOrders(customerId)
  }, [customerId])

  const fetchOrders = (customerId) => {
    orderService.findByCustomer(customerId).then(
      (response) => {
        setOrders(response.data)
        if (orderId) {
          const orderArray = response.data.filter((item) => item.id == orderId)
          console.log('order: ', orderArray)
          if (orderArray[0]) {
            setValue('orderId', orderId)
            setValue('amount', utils.formatFloat(orderArray[0].toPay.toFixed(2)))
            setValue('accountId', '31')
          }
        }
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const fetchCustomers = () => {
    customerService.getAll(rabbit.companyId).then(
      (response) => {
        setCustomers(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // const fetchEnums = (service, setFunc, type) => {
  //   service.findByType(type).then(
  //     (response) => {
  //       setFunc(response.data)
  //     },
  //     (error) => {
  //       setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
  //     }
  //   )
  // }

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        customerId: idCustomer,
        orderId: orderId || 'n',
        accountId: '31',
        dateTransaction: DateTime.now(),
        amount: '0,0'
      })
    } else {
      transactionService.get(idObj).then(
        (response) => {
          response.data.amount = utils.formatFloat(response.data.amount)
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const onSubmit = (data) => {
    data.amount = data.amount.replace(',', '.')
    if (data.dateInvoice === '') {
      data.dateInvoice = null
    }
    if (validate(data)) {
      if (idObj === 'new') {
        data.customerId = idCustomer
        addObj(data)
      } else {
        data.id = obj.id
        updateObj(data, obj.id)
      }
    }
  }

  const validate = (order) => {
    const inv = order.invoice
    const d = order.dateInvoice
    if ((inv === '' && d !== null) || (inv !== '' && d === null)) {
      setShowSnackbar({ sh: true, msg: 'inserire tutti i dati della fattura o nessuno', color: 'error' })
      return false
    }
    return true
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    transactionService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/customers/${idCustomer}/payments`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    transactionService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/customers/${idCustomer}/payments`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Pagamento' icon='euro' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.customerId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.customerId}
                        >
                          <InputLabel>Cliente</InputLabel>
                          <Select
                            label='Cliente'
                            defaultValue={obj.customerId}
                            {...register('customerId', { required: true })}
                          >
                            {customers && customers.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.surname} {item.name} {item.businessName}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.orderId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.orderId}
                        >
                          <InputLabel>Ordine</InputLabel>
                          <Select
                            label='Ordine'
                            defaultValue={obj.orderId}
                            {...register('orderId', { required: true })}
                          >
                            {orders && orders.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.number} € {utils.formatFloat(item.toPay)}/{utils.formatFloat(item.totalAmount)}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!(errors.dateTransaction)}
                        {...register('dateTransaction', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='importo'
                        error={!!(errors.amount)}
                        helperText={errors.amount && 'dati non corretti'}
                        {...register('amount', { required: true, pattern: utils.floatPattern })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        label='data fattura'
                        error={!!(errors.dateInvoice)}
                        {...register('dateInvoice')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='num fattura'
                        error={!!(errors.invoice)}
                        {...register('invoice')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        error={!!(errors.notes)}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.accountId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.accountId}
                        >
                          <InputLabel>modalità pagamento</InputLabel>
                          <Select
                            label='modalità pagamento'
                            defaultValue={obj.accountId}
                            {...register('accountId', { required: true })}
                          >
                            {enums && enums.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to={`/customers/${idCustomer}/payments`}
                  >
                    pagamenti del cliente
                  </MDButton>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/payments' sx={{ ml: 1 }}
                  >
                    tutti i pagamenti
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Ordine</MDTypography>
                <Stack spacing={2}>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer}
                  >
                    cliente
                  </MDButton>
                  {/* <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/addresses'}
                  >
                    indirizzi
                  </MDButton> */}
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/orders'}
                  >
                    ordini
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/payments'}
                  >
                    pagamenti
                  </MDButton>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
