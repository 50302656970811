const prod = {
  SERVER_URL: 'https://adisuitesw.01rabbit.it/api',
  SAMPLE_DATA: false,
  APP_NAME: 'ADI Suite'
}

const dev = {
  SERVER_URL: 'http://localhost:8080/api',
  SAMPLE_DATA: false,
  APP_NAME: 'ADI Suite dev.'
}

export const config = process.env.NODE_ENV === 'production' ? prod : dev
