'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
// import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
// import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import customerService from 'services/customer.service'
import mainService from 'services/order.service'
import enumerationService from 'services/enumeration.service'
import { useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RabbitContext from 'context/rabbit.js'

export default function OrderTable () {
  const [objId, setObjId] = useState(0)
  const { idCustomer } = useParams()
  // const [enums, setEnums] = React.useState([])
  const [obj, setObj] = useState([])
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = useState(false)
  const { data: enums, isLoading: loadEnum, error: errEnums } = enumerationService.findByType('orderState')
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObjects()
    // fetchEnums(enumerationService, setEnums, 'orderState')
  }, [])

  const fetchObjects = () => {
    if (idCustomer) {
      customerService.get(idCustomer).then(
        (response) => {
          setObj(response.data)
        })
        .then(() => {
          return mainService.findByCustomer(idCustomer)
        })
        .then((response2) => {
          setObjects(response2.data)
        })
        .catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    } else {
      mainService.getAll(rabbit.companyId).then(
        (response) => {
          setObjects(response.data)
        })
        .catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    }
  }

  // const fetchEnums = (service, setFunc, type) => {
  //   service.findByType(type).then(
  //     (response) => {
  //       setFunc(response.data)
  //     },
  //     (error) => {
  //       setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
  //     }
  //   )
  // }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const columns = [
    {
      Header: 'cliente',
      accessor: (row) => row.customer.surname.toUpperCase() + ' ' + row.customer.name.toUpperCase()
    },
    // {
    //   Header: 'preordine',
    //   accessor: 'preOrder',
    //   Cell: ({ value }) => value ? <CheckBoxIcon color='info' /> : ''
    // },
    {
      Header: 'estensione',
      accessor: 'extension',
      Cell: ({ value }) => value ? <CheckBoxIcon color='info' /> : ''
    },
    {
      Header: 'numero',
      accessor: 'number'
    },
    {
      Header: 'stato',
      accessor: (row) => enumerationService.getEnumerationDescription(enums, row.stateId)
    },
    {
      Header: 'data',
      accessor: d => {
        return utils.formatDate(d.dateOrder, 'dd/MM/yyyy')
      }
    },
    {
      Header: 'inizio',
      accessor: d => {
        return d.dateBegin ? utils.formatDate(d.dateBegin, 'dd/MM/yyyy') : '-'
      }
    },
    {
      Header: 'giorni',
      accessor: 'quantity'
    },
    {
      Header: 'prezzo',
      accessor: 'price',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'totale',
      accessor: 'totalAmount',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'da pagare',
      accessor: 'toPay',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'seriale',
      accessor: (row) => row.product ? row.product.serial + ' | ' + row.product.batch + ' [' + (row.companyId === 1 ? 'T' : 'B') + ']' : '---'
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: (row) => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            component={Link}
            to={`/customers/${row.customer.id}/orders/${row.id}`}
            sx={{ mr: 1 }}
          >
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            color='info'
            size='small'
            iconOnly
            component={Link}
            to={`/customers/${row.customer.id}/orders/${row.id}?extension=1`}
            sx={{ mr: 1 }}
          >
            <Icon>add</Icon>
          </MDButton>
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setObjId(row.id)
              setOpenConfirm(true)
            }}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm bgColor='info' title='Ordini' icon='list_alt' />
          {idCustomer &&
            <MDBox p={3} lineHeight={1}>
              <MDButton color='secondary' component={Link} to='/customers' sx={{ m: 1 }}>
                indietro
              </MDButton>
              <MDButton color='info' component={Link} to={`/customers/${idCustomer}/orders/new`} m={1}>
                aggiungi
              </MDButton>
            </MDBox>}
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
