'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
// import CheckBoxIcon from '@mui/icons-material/CheckBox'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
// import customerService from 'services/customer.service'
import mainService from 'services/transaction.service'
import enumerationService from 'services/enumeration.service'
import { useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RabbitContext from 'context/rabbit.js'

export default function TransactionTable () {
  const [objId, setObjId] = useState(0)
  const { idCustomer } = useParams()
  // const [enums, setEnums] = useState([])
  // const [obj, setObj] = useState([])
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = useState(false)
  const { rabbit } = useContext(RabbitContext)
  const { data: enums, isLoading: loadEnum, error: errEnums } = enumerationService.findByType('account')

  useEffect(() => {
    fetchObjects()
  }, [])

  // useEffect(() => {
  //   fetchEnums(enumerationService, setEnums, 'account')
  // }, [])

  const fetchObjects = () => {
    if (idCustomer) {
      mainService.findByCustomer(idCustomer).then(
        (response) => {
          setObjects(response.data)
        })
        .catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    } else {
      mainService.getAll(rabbit.companyId).then(
        (response) => {
          setObjects(response.data)
        })
        .catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    }
  }

  // const fetchEnums = (service, setFunc, type) => {
  //   service.findByType(type).then(
  //     (response) => {
  //       setFunc(response.data)
  //     },
  //     (error) => {
  //       setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
  //     }
  //   )
  // }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const columns = [
    {
      Header: 'data',
      accessor: d => {
        return utils.formatDate(d.dateTransaction, 'dd/MM/yyyy')
      }
    },
    {
      Header: 'modalità',
      accessor: (row) => enumerationService.getEnumerationDescription(enums, row.accountId)
    },
    {
      Header: 'cliente',
      accessor: (row) => `${row.customer.surname} ${row.customer.name}`
    },
    {
      Header: 'ordine',
      accessor: (row) => `${row.order.number}`
    },
    {
      Header: 'fattura',
      accessor: (row) => row.dateInvoice ? `${row.invoice} - ${utils.formatDate(row.dateInvoice, 'dd/MM/yyyy')}` : '-'
    },
    {
      Header: 'importo',
      accessor: 'amount',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: (row) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/customers/${row.customer.id}/payments/${row.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(row.id)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm bgColor='info' title='Pagamenti' icon='euro' />
          <MDBox p={3} lineHeight={1}>
            <MDButton color='secondary' component={Link} to='/customers' sx={{ m: 1 }}>
              clienti
            </MDButton>
            <MDButton color='info' component={Link} to={`/customers/${idCustomer}/payments/new`} m={1}>
              aggiungi
            </MDButton>
          </MDBox>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
