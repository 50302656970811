
import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import MDInput from 'components/MDInput'
import { Controller } from 'react-hook-form'

const ZUAutoComplete = forwardRef(({ name, label, control, options, value, getOptionLabel, required, rules, error }, ref) => (

  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange } }) => (
      <Autocomplete
        options={options}
        value={value}
        required={required}
        onChange={(_, item) => onChange(item ? item.id : null)}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(item, val) => {
          return item.id === val.id
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            paddingTop: '5px',
            paddingBottom: '4px'

          }
        }}
        // disableClearable
        renderInput={params => (
          <MDInput
            {...params}
            fullWidth
            required={required}
            label={label}
            error={error}
          />
        )}
      />
    )}
  />

))

// Setting default values for the props
ZUAutoComplete.defaultProps = {
  required: false,
  error: false
}

// Typechecking props for the ZUTitleForm
ZUAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  rules: PropTypes.object.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool
}

export default ZUAutoComplete
