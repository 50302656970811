import React, { useEffect, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'

// import Switch from '@mui/material/Switch'
// import Select from '@mui/material/Select'
import Icon from '@mui/material/Icon'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
// import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import RabbitContext from 'context/rabbit.js'

// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import customerService from 'services/customer.service'
import { useForm } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'

export default function CustomerForm () {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({})
    } else {
      customerService.get(idObj).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const onSubmit = (data) => {
    data.companyId = rabbit.companyId
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    customerService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        const cust = response.data
        navigate(`/customers/${cust.id}/orders`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    customerService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/customers')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Cliente' icon='person' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        autoFocus
                        label='nome'
                        error={!!(errors.name)}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cognome'
                        error={!!(errors.surname)}
                        {...register('surname', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='ragione sociale'
                        error={!!(errors.businessName)}
                        {...register('businessName', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='telefono'
                        error={!!(errors.phone)}
                        {...register('phone', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='cellulare'
                        error={!!(errors.mobile)}
                        {...register('mobile', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='email'
                        label='email'
                        error={!!(errors.email)}
                        {...register('email', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'mail non valida'
                          }
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>Dati di spedizione</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='indirizzo'
                        error={!!(errors.address)}
                        {...register('address', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='città'
                        error={!!(errors.city)}
                        {...register('city', { required: true })}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='prov'
                        inputProps={{
                          maxLength: 2
                        }}
                        error={!!(errors.prov)}
                        {...register('prov', { required: true })}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={2}>
                      <Autocomplete
                        disablePortal
                        options={utils.province}
                        renderInput={(params) => <MDInput
                          {...params}
                          fullWidth
                          required
                          label='Provincia'
                          error={!!(errors.prov)}
                          {...register('prov', { required: true })}
                                                 />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cap'
                        inputProps={{
                          maxLength: 5
                        }}
                        error={!!(errors.cap)}
                        {...register('cap', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        error={!!(errors.notes)}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>Dati di fatturazione</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='nome'
                        error={!!(errors.invoiceName)}
                        {...register('invoiceName', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='cognome'
                        error={!!(errors.invoiceSurname)}
                        {...register('invoiceSurname', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='ragione sociale'
                        error={!!(errors.invoiceBusiness)}
                        {...register('invoiceBusiness', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='indirizzo'
                        error={!!(errors.invoiceAddress)}
                        {...register('invoiceAddress', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='città'
                        error={!!(errors.invoiceCity)}
                        {...register('invoiceCity', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <Autocomplete
                        disablePortal
                        options={utils.province}
                        renderInput={(params) => <MDInput
                          {...params}
                          fullWidth
                          label='Provincia'
                          error={!!(errors.invoiceProv)}
                          {...register('invoiceProv', { required: false })}
                                                 />}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='provincia'
                        inputProps={{
                          maxLength: 2
                        }} error={!!(errors.invoiceProv)}
                        {...register('invoiceProv', { required: false })}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='cap'
                        inputProps={{
                          maxLength: 5
                        }}
                        error={!!(errors.invoiceCap)}
                        {...register('invoiceCap', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='partita iva'
                        error={!!(errors.vat)}
                        {...register('vat', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='codice fiscale'
                        error={!!(errors.fiscalCode)}
                        {...register('fiscalCode', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='email'
                        required={false}
                        label='pec'
                        error={!!(errors.pec)}
                        {...register('pec', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='codice destinatario'
                        required={false}
                        error={!!(errors.codeInvoice)}
                        {...register('codeInvoice', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/customers/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Cliente</MDTypography>
                {idObj !== 'new' &&

                  <Stack spacing={2}>
                    {/* <MDButton
                      color='info'
                      component={Link}
                      to={'/customers/' + idObj + '/addresses'}
                    >
                      indirizzi
                    </MDButton> */}
                    <MDButton
                      color='info'
                      component={Link}
                      to={'/customers/' + idObj + '/orders'}
                    >
                      ordini
                    </MDButton>
                    <MDButton
                      color='info'
                      component={Link}
                      to={'/customers/' + idObj + '/payments'}
                    >
                      pagamenti
                    </MDButton>
                  </Stack>}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
