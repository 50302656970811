/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react'

// react-router-dom components
import { Link } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook'
import GitHubIcon from '@mui/icons-material/GitHub'
import GoogleIcon from '@mui/icons-material/Google'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDSnackbar from 'components/MDSnackbar'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'

// ZeroUno
import { config } from 'variables/Config.js'
import authService from 'services/auth.service'
import { useForm } from 'react-hook-form'

function Basic () {
  const [rememberMe, setRememberMe] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [errorMsg, setErrorMsg] = useState('')
  const [tr, setTR] = useState(false)

  const handleSetRememberMe = () => setRememberMe(!rememberMe)

  const login = (formData) => {
    authService.login(formData.username, formData.password).then(
      (data) => {
        window.location.href = '/'
      },
      (error) => {
        console.log('error: ' + error)
        if (error.response) {
          console.error(error.response.data.message)
          showNotification(error.response.data.message)
        } else {
          showNotification('errore login')
        }
      }
    )
  }

  const showNotification = (message) => {
    if (!tr) {
      setTR(true)
      setTimeout(function () {
        setTR(false)
      }, 6000)
      setErrorMsg(message)
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='light' color='white'>
            {config.APP_NAME}
          </MDTypography>
          {config.SAMPLE_DATA &&
            <Grid container spacing={3} justifyContent='center' sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={2}>
                <MDTypography component={MuiLink} href='#' variant='body1' color='white'>
                  <FacebookIcon color='inherit' />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography component={MuiLink} href='#' variant='body1' color='white'>
                  <GitHubIcon color='inherit' />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography component={MuiLink} href='#' variant='body1' color='white'>
                  <GoogleIcon color='inherit' />
                </MDTypography>
              </Grid>
            </Grid>}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(login)}>
            <MDBox mb={2}>
              <MDInput
                type='username' label='username' fullWidth
                autoFocus
                error={!!(errors.username)}
                required
                {...register('username', { required: true })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type='password' label='Password' fullWidth
                error={!!errors.password}
                required
                autoComplete='off'
                {...register('password', { required: true })}
              />
            </MDBox>
            {config.SAMPLE_DATA &&
              <MDBox display='flex' alignItems='center' ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant='button'
                  fontWeight='regular'
                  color='text'
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>}
            <MDBox mt={4} mb={1}>
              <MDButton variant='gradient' color='info' fullWidth type='submit'>
                accedi
              </MDButton>
            </MDBox>
            {config.SAMPLE_DATA &&
              <MDBox mt={3} mb={1} textAlign='center'>
                <MDTypography variant='button' color='text'>
                  Don&apos;t have an account?{' '}
                  <MDTypography
                    component={Link}
                    to='/authentication/sign-up/cover'
                    variant='button'
                    color='info'
                    fontWeight='medium'
                    textGradient
                  >
                    Registrati
                  </MDTypography>
                </MDTypography>
              </MDBox>}
          </form>
        </MDBox>
      </Card>
      <MDSnackbar
        color='error'
        icon='notifications'
        title='Errore'
        dateTime='ora'
        content={errorMsg}
        open={tr}
        close={() => setTR(false)}
      />
    </BasicLayout>
  )
}

export default Basic
