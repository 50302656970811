/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDInput from 'components/MDInput'
import MDBox from 'components/MDBox'

function FormField ({ label, ...rest }) {
  return (
    <MDBox mb={2}>
      <MDInput {...rest} variant='standard' label={label} fullWidth />
    </MDBox>
  )
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired
}

export default FormField
