/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context'

function MiniStatisticsCard ({ bgColor, title, count, percentage, icon, direction }) {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  return (
    <Card sx={{ overflow: 'hidden' }}>
      <MDBox
        bgColor={bgColor}
        variant='gradient'
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card
        })}
      >
        <MDBox p={2}>
          <Grid container alignItems='center'>
            {direction === 'left'
              ? (
                <Grid item xs={4}>
                  <MDBox
                    variant='gradient'
                    bgColor={bgColor === 'white' ? icon.color : 'white'}
                    color={bgColor === 'white' ? 'white' : 'dark'}
                    width='4rem'
                    height='4rem'
                    borderRadius='md'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    shadow='md'
                  >
                    <Icon fontSize='medium' color='inherit'>
                      {icon.component}
                    </Icon>
                  </MDBox>
                </Grid>
                )
              : null}
            <Grid item xs={8}>
              <MDBox
                ml={direction === 'left' ? 2 : 0}
                lineHeight={1}
                textAlign={direction === 'left' ? 'right' : 'left'}
              >
                <MDTypography
                  variant='button'
                  color={bgColor === 'white' ? 'text' : 'white'}
                  opacity={bgColor === 'white' ? 1 : 0.7}
                  textTransform='capitalize'
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </MDTypography>
                <MDTypography
                  variant='h5'
                  fontWeight='bold'
                  color={bgColor === 'white' ? 'dark' : 'white'}
                >
                  {count}{' '}
                  <MDTypography variant='button' color={percentage.color} fontWeight='bold'>
                    {percentage.text}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Grid>
            {direction === 'right'
              ? (
                <Grid item xs={4}>
                  <MDBox
                    variant='gradient'
                    bgColor={bgColor === 'white' ? icon.color : 'white'}
                    color={bgColor === 'white' ? 'white' : 'dark'}
                    width='4rem'
                    height='4rem'
                    marginLeft='auto'
                    borderRadius='md'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    shadow='md'
                  >
                    <Icon fontSize='medium' color='inherit'>
                      {icon.component}
                    </Icon>
                  </MDBox>
                </Grid>
                )
              : null}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: 'white',
  title: {
    fontWeight: 'light',
    text: ''
  },
  percentage: {
    color: 'success',
    text: ''
  },
  direction: 'right'
}

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark'
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
    text: PropTypes.string
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'white'
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
    component: PropTypes.node.isRequired
  }).isRequired,
  direction: PropTypes.oneOf(['right', 'left'])
}

export default MiniStatisticsCard
