/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import US from 'assets/images/icons/flags/US.png'
import DE from 'assets/images/icons/flags/DE.png'
import GB from 'assets/images/icons/flags/GB.png'
import BR from 'assets/images/icons/flags/BR.png'

export default [
  {
    country: [US, 'united state'],
    sales: 2500,
    value: '$230,900',
    bounce: '29.9%'
  },
  {
    country: [DE, 'germany'],
    sales: '3.900',
    value: '$440,000',
    bounce: '40.22%'
  },
  {
    country: [GB, 'great britain'],
    sales: '1.400',
    value: '$190,700',
    bounce: '23.44%'
  },
  { country: [BR, 'brasil'], sales: 562, value: '$143,960', bounce: '32.14%' }
]
