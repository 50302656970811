/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Billing page components
import Bill from 'layouts/pages/account/billing/components/Bill'

function BillingInformation () {
  return (
    <Card id='delete-account'>
      <MDBox pt={3} px={2}>
        <MDTypography variant='h6' fontWeight='medium'>
          Billing Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component='ul' display='flex' flexDirection='column' p={0} m={0}>
          <Bill
            name='oliver liam'
            company='viking burrito'
            email='oliver@burrito.com'
            vat='FRB1235476'
          />
          <Bill
            name='lucas harper'
            company='stone tech zone'
            email='lucas@stone-tech.com'
            vat='FRB1235476'
          />
          <Bill
            name='ethan james'
            company='fiber notion'
            email='ethan@fiber.com'
            vat='FRB1235476'
            noGutter
          />
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default BillingInformation
