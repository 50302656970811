'use strict'

import { useEffect, useState, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
// import Autocomplete from '@mui/material/Autocomplete'
// import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Link as MuiLink } from '@mui/material'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import customerService from 'services/customer.service'
import orderService from 'services/order.service'
import productService from 'services/product.service'
import agentService from 'services/agent.service'
import doctorService from 'services/doctor.service'
import enumerationService from 'services/enumeration.service'
import pathologyService from 'services/pathology.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'
import { config } from 'variables/Config.js'
import SignatureCanvas from 'react-signature-canvas'

export default function OrderForm () {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [obj, setObj] = useState({})
  const [pathology, setPathology] = useState({})
  const [lastNumber, setLastNumber] = useState()
  const [products, setProducts] = useState([])
  const [alertProducts, setAlertProducts] = useState(false)
  const [customers, setCustomers] = useState([])
  const [agents, setAgents] = useState([])
  const [pathologies, setPathologies] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, control, getValues, setValue, setError, reset, watch } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const [sigPad, setSigPad] = useState({})
  const [signature, setSignature] = useState()
  const [openDlg, setOpenDlg] = useState(false)

  const { data: doctors, isLoading, error } = doctorService.getAll(rabbit.companyId)
  const { data: enums, isLoading: loadEnum, error: errEnums } = enumerationService.findByType('orderState')

  const watchFields = watch(['quantity', 'totalAmount', 'vat', 'shippingCosts', 'shippingVat'])
  const watchPathology = watch('pathologyId')
  const extension = new URLSearchParams(useLocation().search).get('extension')

  const clearCanvas = () => {
    if (sigPad !== {}) { sigPad.clear() }
  }
  const trimCanvas = () => {
    if (sigPad !== {}) {
      setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
      setOpenDlg(false)
    }
  }

  useEffect(() => {
    fetchAccessorsCompany(agentService, setAgents)
    fetchAccessors(pathologyService, setPathologies)
    fetchAccessorsCompany(customerService, setCustomers)
    fetchObject()
    fetchLastNumber()
  }, [])

  useEffect(() => {
    reset({ ...obj })
    setValue('dateOrder', DateTime.fromISO(obj.dateOrder).toISODate())
    setValue('dateBegin', DateTime.fromISO(obj.dateBegin).toISODate())
    setValue('dateReturn', DateTime.fromISO(obj.dateReturn).toISODate())
    setValue('dateEnd', DateTime.fromISO(obj.dateEnd).toISODate())
  }, [obj, products])

  useEffect(() => {
    calculateTotal()
  }, [watchFields])

  useEffect(() => {
    findPathology(watchPathology)
  }, [watchPathology])

  const findPathology = (id) => {
    const pathology = utils.getObjectFromArray(pathologies, watchPathology)
    console.log('pathology: ', pathology)

    setPathology(pathology)
  }
  const calculateTotal = () => {
    const qta = String(watchFields[0]).replace(',', '.')
    const totalAmount = String(watchFields[1]).replace(',', '.')
    const vat = watchFields[2]
    const shc = String(watchFields[3]).replace(',', '.')
    const shv = watchFields[4]

    const price = (totalAmount - shc * (1 + shv / 100)) / (qta * (1 + vat / 100))
    setValue('price', String(price.toFixed(2)).replace('.', ','))
    // const totalAmount = qta * price * (1 + vat / 100) + shc * (1 + shv / 100)
    // setValue('totalAmount', String(totalAmount.toFixed(2)).replace('.', ','))
  }

  const fetchProducts = () => {
    productService.getFree(1).then(
      (response1) => {
        setProducts((products) => [...products, ...response1.data])
      }).then(
      productService.getFree(2).then(
        (response2) => {
          setProducts((products) => [...products, ...response2.data])
        }).catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
    ).catch((error) => {
      setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
    })
  }

  const fetchAccessors = (service, setFunc) => {
    service.getAll().then(
      (response) => {
        setFunc(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const fetchAccessorsCompany = (service, setFunc) => {
    service.getAll(rabbit.companyId).then(
      (response) => {
        setFunc(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const fetchLastNumber = () => {
    orderService.lastOrderNumber(rabbit.companyId).then(
      (response) => {
        const ln = response.data
        if (!ln[0]) {
          setLastNumber([{ number: '0', invoiceNumber: 'FPR 00' }])
        }
        setLastNumber(response.data)
      }).catch(() => {
      setLastNumber([{ number: '0', invoiceNumber: 'FPR 00' }])
    })
  }

  const fetchObject = () => {
    if (idObj === 'new') {
      customerService.get(idCustomer).then(
        (response) => {
          const cust = response.data
          setObj({
            number: '',
            customerId: idCustomer,
            productId: 'n',
            stateId: '1',
            agentId: 'n',
            doctorId: 'n',
            pathologyId: 'n',
            dateOrder: DateTime.now(),
            // dateBegin: DateTime.now().plus({ days: 1 }),
            dateReturn: null,
            quantity: 20,
            price: '10,0',
            vat: 4.0,
            shippingCosts: 0.0,
            shippingVat: 22.0,
            totalAmount: 208.0,
            extension: false,
            address: cust.address,
            city: cust.city,
            cap: cust.cap,
            prov: cust.prov,
            notes: cust.notes,
            invoiceNumber: 'FPR 00',
            invoiceName: cust.invoiceName,
            invoiceSurname: cust.invoiceSurname,
            invoiceBusiness: cust.invoiceBusiness,
            invoiceAddress: cust.invoiceAddress,
            invoiceCity: cust.invoiceCity,
            invoiceCap: cust.invoiceCap,
            invoiceProv: cust.invoiceProv,
            invoiceDescription: 'trattamento octopus ',
            vatNumber: cust.vat,
            fiscalCode: cust.fiscalCode,
            pec: cust.pec,
            codeInvoice: cust.codeInvoice
          })
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      ).then(
        fetchProducts()
      )
    } else if (extension != null) {
      orderService.get(idObj).then(
        (response) => {
          const orig = response.data
          orig.price = utils.formatFloat(orig.price)
          orig.shippingCosts = utils.formatFloat(orig.shippingCosts)
          setObj({
            number: orig.number + '_ext',
            customerId: idCustomer,
            productId: orig.productId,
            product: orig.product,
            stateId: '3',
            agentId: orig.agentId,
            doctorId: orig.doctorId,
            pathologyId: orig.pathologyId,
            dateOrder: DateTime.now(),
            dateBegin: DateTime.fromISO(orig.dateBegin).plus({ days: orig.quantity }),
            dateReturn: null,
            quantity: 20,
            price: 0.0,
            vat: orig.vat,
            shippingCosts: 0.0,
            shippingVat: 22.0,
            totalAmount: 0.0,
            extension: true,
            address: orig.address,
            city: orig.city,
            cap: orig.cap,
            prov: orig.prov,
            notes: orig.notes,
            invoiceNumber: 'FPR 00',
            invoiceName: orig.invoiceName,
            invoiceSurname: orig.invoiceSurname,
            invoiceBusiness: orig.invoiceBusiness,
            invoiceAddress: orig.invoiceAddress,
            invoiceCity: orig.invoiceCity,
            invoiceCap: orig.invoiceCap,
            invoiceProv: orig.invoiceProv,
            invoiceDescription: 'trattamento octopus ',
            vatNumber: orig.vatNumber,
            fiscalCode: orig.fiscalCode,
            pec: orig.pec,
            codeInvoice: orig.codeInvoice
          })
          setProducts((products) => [orig.product, ...products])
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      ).then(
        fetchProducts()
      )
    } else {
      orderService.get(idObj).then(
        (response) => {
          response.data.price = utils.formatFloat(response.data.price)
          response.data.shippingCosts = utils.formatFloat(response.data.shippingCosts)
          if (response.data.doctorId == null) { response.data.doctorId = 'n' }
          if (response.data.agentId == null) { response.data.agentId = 'n' }
          if (response.data.productId == null) { response.data.productId = 'n' } else {
            setProducts([response.data.product])
          }
          setObj(response.data)
          setSignature(response.data.signature)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      ).then(
        fetchProducts()
      )
    }
  }

  // const getModelName = (id) => {
  //   const name =
  //   productModels &&
  //   productModels.filter((item) => item.id == id)[0] &&
  //   productModels.filter((item) => item.id == id)[0].name
  //   return name
  // }

  const onSubmit = (data) => {
    data.price = String(data.price).replace(',', '.')
    data.totalAmount = String(data.totalAmount).replace(',', '.')
    console.log('data.price: ', data.price)
    data.shippingCosts = String(data.shippingCosts).replace(',', '.')
    const s = data.stateId
    const d = data.dateBegin
    const r = data.dateReturn
    if ((s === 1 || s === 2) && (d || d === '')) {
      data.dateBegin = null
    }
    if ((s !== 4) && (r || r === '')) {
      data.dateReturn = null
    }
    if (data.doctorId === 'n') { data.doctorId = null }
    if (data.productId === 'n') { data.productId = null }
    data.signature = signature
    if (validate(data)) {
      if (idObj === 'new' || extension != null) {
        data.customerId = idCustomer
        addObj(data)
      } else {
        data.id = obj.id
        updateObj(data, obj.id)
      }
    }
  }

  const validate = (order) => {
    if (order.agentId === 'n') {
      setError('agentId', { type: 'custom', message: 'campo obbligatorio' })
      setShowSnackbar({ sh: true, msg: 'inserire agente', color: 'error' })
      return false
    }
    if (order.pathologyId === 'n') {
      setError('pathologyId', { type: 'custom', message: 'campo obbligatorio' })
      setShowSnackbar({ sh: true, msg: 'inserire patologia', color: 'error' })
      return false
    }
    // stato aperto 1 data inizio = null
    // stato spedito 2 data inizio = null
    // stato consegnato 3 data inizio !=null
    // stato chiuso 4 data inizio !=null
    // stato annullato 5 fai che cazzo vuoi
    const s = order.stateId
    const d = order.dateBegin
    const r = order.dateReturn
    if ((s === 3 || s === 4) && !d) {
      setShowSnackbar({ sh: true, msg: 'inserire la data di inizio', color: 'error' })
      return false
    }
    if ((s === 4) && !r) {
      setShowSnackbar({ sh: true, msg: 'inserire la data di rientro', color: 'error' })
      return false
    }
    return true
  }
  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    orderService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/customers/${idCustomer}/orders`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    orderService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/customers/${data.customerId}/orders`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={9}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Ordine' icon='list_alt' />

                <MDBox p={2}>
                  {alertProducts && <MDAlert color='error'>Non ci sono prodotti disponibili</MDAlert>}

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='numero ordine'
                        error={!!(errors.number)}
                        {...register('number', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      {idObj == 'new' && lastNumber && lastNumber[0] &&
                        <MDTypography variant='subtitle2'>ultimo ordine: {lastNumber[0].number}, ultima fattura: {lastNumber[0].invoiceNumber}</MDTypography>}
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.customerId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.customerId}

                        >
                          <InputLabel>Cliente</InputLabel>
                          <Select
                            label='Cliente'
                            defaultValue={obj.customerId}
                            readOnly
                            {...register('customerId', { required: true })}
                          >
                            {customers && customers.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.surname} {item.name} {item.businessName}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.productId && (
                        <ZUAutoComplete
                          name='productId'
                          control={control}
                          required={false}
                          rules={{ required: false }}
                          options={products}
                          value={obj.product}
                          getOptionLabel={(item) => `${item.productModel.name} ser. ${item.serial} | ${item.batch} [${item.companyId === 1 ? 'T' : 'B'}]`}
                          label='Dispositivo'
                          error={!!(errors.productId)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {true && obj.pathologyId && (
                        <ZUSelect
                          error={!!errors.pathologyId}
                          label='Patologia'
                          defaultValue={obj.pathologyId}
                          register={register}
                          required
                          name='pathologyId'
                          rules={{ required: true }}
                        >
                          <MenuItem key='0' value='n'>seleziona una patologia</MenuItem>
                          {pathologies && pathologies.map((item) =>
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                          )}
                        </ZUSelect>
                      )}
                      {false && pathology && (
                        <ZUAutoComplete
                          name='pathologyId'
                          control={control}
                          required
                          rules={{ required: true }}
                          options={pathologies}
                          value={pathology}
                          getOptionLabel={(item) => item.name ? `${item.name}` : ''}
                          label='Patologia'
                          error={!!(errors.pathologyId)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.agentId && (
                        <ZUSelect
                          error={!!errors.agentId}
                          label='Agente'
                          defaultValue={obj.agentId}
                          register={register}
                          name='agentId'
                          required
                          rules={{ required: true }}
                        >
                          <MenuItem key='0' value='n'>seleziona un agente</MenuItem>
                          {agents && agents.map((item) =>
                            <MenuItem key={item.id} value={item.id}>{item.surname} {item.name}</MenuItem>
                          )}
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.doctorId && (
                        <ZUSelect
                          error={!!errors.doctorId}
                          label='Medico'
                          required={false}
                          defaultValue={obj.doctorId}
                          register={register}
                          name='doctorId'
                          rules={{ required: false }}
                        >
                          <MenuItem key='0' value='n'>seleziona un medico</MenuItem>
                          {doctors && doctors.map((item) =>
                            <MenuItem key={item.id} value={item.id}>{item.surname} {item.name}</MenuItem>
                          )}
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note patologia'
                        error={!!(errors.notesPathology)}
                        {...register('notesPathology', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>Dati di spedizione</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='indirizzo'
                        error={!!(errors.address)}
                        {...register('address', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='città'
                        error={!!(errors.city)}
                        {...register('city', { required: true })}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={2}>
                      <Autocomplete
                        disablePortal
                        options={utils.province}
                        renderInput={(params) => <MDInput
                          {...params}
                          fullWidth
                          required
                          label='Provincia'
                          error={!!(errors.prov)}
                          {...register('prov', { required: true })}
                                                 />}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='prov'
                        inputProps={{
                          maxLength: 2
                        }}
                        error={!!(errors.prov)}
                        {...register('prov', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cap'
                        inputProps={{
                          maxLength: 5
                        }}
                        error={!!(errors.cap)}
                        {...register('cap', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        autoFocus
                        required
                        label='data'
                        error={!!(errors.dateOrder)}
                        {...register('dateOrder', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required={false}
                        label='data inizio'
                        error={!!(errors.dateBegin)}
                        {...register('dateBegin', { required: false })}
                      />

                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required={false}
                        label='data rientro'
                        error={!!(errors.dateReturn)}
                        {...register('dateReturn', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} />
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>Calcolo prezzo:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='giorni'
                        error={!!(errors.quantity)}
                        {...register('quantity', { required: true, pattern: utils.integerPattern })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='totale'
                        defaultValue={obj.totalAmount}
                        {...register('totalAmount', { required: true, pattern: utils.floatPattern })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        disabled
                        label='prezzo'
                        error={!!(errors.price)}
                        helperText={errors.price && 'dati non corretti'}
                        {...register('price')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      {obj.vat && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.vat}
                        >
                          <InputLabel>iva</InputLabel>
                          <Select
                            label='iva'
                            defaultValue={obj.vat}
                            {...register('vat', { required: true })}
                          >
                            <MenuItem key={0} value={0.0}>0 %</MenuItem>
                            <MenuItem key={4} value={4.0}>4 %</MenuItem>
                            <MenuItem key={10} value={10.0}>10 %</MenuItem>
                            <MenuItem key={22} value={22.0}>22 %</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='costi spedizione'
                        error={!!(errors.shippingCosts)}
                        helperText={errors.shippingCosts && 'dati non corretti'}
                        {...register('shippingCosts', { required: true, pattern: utils.floatPattern })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      {obj.vat && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.vat}
                        >
                          <InputLabel>iva spedizione</InputLabel>
                          <Select
                            label='iva spedizione'
                            defaultValue={obj.shippingVat}
                            {...register('shippingVat', { required: true })}
                          >
                            <MenuItem key={0} value={0.0}>0 %</MenuItem>
                            <MenuItem key={4} value={4.0}>4 %</MenuItem>
                            <MenuItem key={10} value={10.0}>10 %</MenuItem>
                            <MenuItem key={22} value={22.0}>22 %</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                      <Controller
                        control={control}
                        name='extension'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={<Switch checked={value || false} onChange={onChange} />}
                            label='estensione'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {obj.stateId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.stateId}
                        >
                          <InputLabel>stato</InputLabel>
                          <Select
                            label='stato'
                            defaultValue={obj.stateId}
                            {...register('stateId', { required: true })}
                          >
                            {enums && enums.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>Dati di fatturazione:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='num fattura'
                        error={!!(errors.invoiceNumber)}
                        {...register('invoiceNumber', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='cognome'
                        error={!!(errors.invoiceSurname)}
                        {...register('invoiceSurname', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='nome'
                        error={!!(errors.invoiceName)}
                        {...register('invoiceName', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='ragione sociale'
                        error={!!(errors.invoiceBusiness)}
                        {...register('invoiceBusiness', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='indirizzo'
                        error={!!(errors.invoiceAddress)}
                        {...register('invoiceAddress', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='città'
                        error={!!(errors.invoiceCity)}
                        {...register('invoiceCity', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='provincia'
                        inputProps={{
                          maxLength: 2
                        }} error={!!(errors.invoiceProv)}
                        {...register('invoiceProv', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cap'
                        inputProps={{
                          maxLength: 5
                        }}
                        error={!!(errors.invoiceCap)}
                        {...register('invoiceCap', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='partita iva'
                        error={!!(errors.vatNumber)}
                        {...register('vatNumber', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='codice fiscale'
                        error={!!(errors.fiscalCode)}
                        {...register('fiscalCode', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='email'
                        required={false}
                        label='pec'
                        error={!!(errors.pec)}
                        {...register('pec', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'mail non valida'
                          }
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='codice destinatario'
                        required={false}
                        error={!!(errors.codeInvoice)}
                        {...register('codeInvoice', { required: false })}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6}>
                      {obj.invoiceAddressId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.addressId}
                        >
                          <InputLabel>indirizzo di fatturazione</InputLabel>
                          <Select
                            label='indirizzo di fatturazione'
                            defaultValue={obj.invoiceAddressId}
                            {...register('invoiceAddressId', { required: true })}
                          >
                            {addresses && addresses.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.city} - {item.address} {item.civicNumber}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='descrizione fattura'
                        required={false}
                        error={!!(errors.invoiceDescription)}
                        {...register('invoiceDescription', { required: false })}
                      />
                    </Grid>

                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to={`/customers/${idCustomer}/orders`} sx={{ m: 1 }}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/dashboard/main' sx={{ m: 1 }}
                  >
                    Dashboard
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ m: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Card>
              <MDBox p={2}>
                {/* <MDAvatar
                src={obj.image ? config.SERVER_URL + '/download/' + obj.image : avatar}
                alt='profile-image'
                size='xl'
                shadow='sm'
              /> */}
              </MDBox>
              <MDBox p={2}>
                <MDTypography variant='h6'>Ordine</MDTypography>
                <Stack spacing={2}>
                  {idObj !== 'new' && obj.product &&
                    <MDButton
                      color='success'
                      component={MuiLink}
                      href={config.SERVER_URL + '/orders/ddt/' + obj.id}
                      target='_blank'
                    >
                      pdf ordine / ddt
                    </MDButton>}
                  {idObj !== 'new' && obj.product &&
                    <MDButton
                      color='success'
                      component={MuiLink}
                      href={config.SERVER_URL + '/orders/invoice/' + obj.id}
                      target='_blank'
                    >
                      fattura xml
                    </MDButton>}
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer}
                  >
                    cliente
                  </MDButton>
                  {/* <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/addresses'}
                  >
                    indirizzi
                  </MDButton> */}
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/orders'}
                  >
                    ordini
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/payments'}
                  >
                    pagamenti
                  </MDButton>
                  <MDButton color='info' onClick={() => setOpenDlg(true)}>nuova firma</MDButton>

                  <MDTypography variant='h6'>firma cliente</MDTypography>
                  {signature
                    ? <img
                        src={signature}
                      />
                    : null}
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
      <Dialog
        fullScreen
        open={openDlg}
        onClose={() => setOpenDlg(false)}
        sx={{ background: '#f1f1f1' }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpenDlg(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <MDTypography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Firma
            </MDTypography>
            <MDButton
              color='secondary'
              onClick={(e) => clearCanvas()}
              sx={{ m: 1 }}
            >
              pulisci
            </MDButton>
            <MDButton
              autoFocus
              color='success'
              onClick={(e) => trimCanvas()}
              sx={{ m: 1 }}
            >
              salva
            </MDButton>
          </Toolbar>
        </AppBar>
        <MDBox p={2} bgColor='#cccccc' sx={{ display: 'flex', justifyContent: 'center' }}>
          <MDBox bgColor='#FFFFFF' width='300px' height='200px'>
            <SignatureCanvas
              canvasProps={{ width: '300', height: '200' }}
              penColor='#00ADEF'
              ref={(ref) => { setSigPad(ref) }}
            />
          </MDBox>
        </MDBox>
      </Dialog>
    </DashboardLayout>
  )
}
