'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getAll = (companyId) => {
  return http.axiosClient.get(`/orders?companyId=${companyId}`, {
    headers: authHeader()
  })
}

const get = (id) => {
  return http.axiosClient.get(`/orders/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/orders', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.put(`/orders/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/orders/${id}`, {
    headers: authHeader()
  })
}

const findByCustomer = (customerId) => {
  return http.axiosClient.get(`/orders?customerId=${customerId}`, { headers: authHeader() })
}

const findOpen = (companyId) => {
  return http.axiosClient.get(`/orders/open?companyId=${companyId}`, { headers: authHeader() })
}

const findToPay = (companyId) => {
  return http.axiosClient.get(`/orders/topay?companyId=${companyId}`, { headers: authHeader() })
}

const lastOrderNumber = (companyId) => {
  return http.axiosClient.get(`/orders/lastNumber?companyId=${companyId}`, { headers: authHeader() })
}

const getDashboard = (companyId) => {
  return http.axiosClient.get(`/orders/dashboard?companyId=${companyId}`, { headers: authHeader() })
}

const downloadDdt = (id) => {
  return http.axiosClient.get(`/orders/ddt/${id}`, { headers: authHeader() })
}

const statsProduct = (companyId, from, to) => {
  return http.axiosClient.get(`/orders/stats/product?companyId=${companyId}&from=${from}&to=${to}`, { headers: authHeader() })
}
const statsDoctor = (companyId, from, to) => {
  return http.axiosClient.get(`/orders/stats/doctor?companyId=${companyId}&from=${from}&to=${to}`, { headers: authHeader() })
}
const statsPathology = (companyId, from, to) => {
  return http.axiosClient.get(`/orders/stats/pathology?companyId=${companyId}&from=${from}&to=${to}`, { headers: authHeader() })
}
const statsAgent = (companyId, from, to) => {
  return http.axiosClient.get(`/orders/stats/agent?companyId=${companyId}&from=${from}&to=${to}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByCustomer,
  findOpen,
  findToPay,
  getDashboard,
  statsProduct,
  statsDoctor,
  statsPathology,
  statsAgent,
  lastOrderNumber

}
