import { useEffect, useContext, useState, useMemo } from 'react'

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ComplexStatisticsCard from 'components/Cards/StatisticsCards/ComplexStatisticsCard'
import DefaultItem from 'components/Items/DefaultItem'

// dashboard components
import EventCalendar from 'components/Calendar'

// zerouno
// import customerService from 'services/customer.service'
import orderService from 'services/order.service'
import { Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'

function MainDashboard () {
  // const [objects, setObjects] = useState([])
  const [events, setEvents] = useState([])
  const [toSend, setToSend] = useState([])
  const [toArrive, setToArrive] = useState([])
  const [toRetrieve, setToRetrieve] = useState([])
  const [toPay, setToPay] = useState([])
  const [stats, setStats] = useState({
    year: 0,
    euro: 0,
    prevYear: 0,
    prevEuro: 0,
    month: 0,
    euroM: 0,
    prevMonth: 0,
    prevEuroM: 0
  })
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObjects()
    fetchStats()
    fetchToPay()
  }, [rabbit.companyId])

  const fetchObjects = () => {
    orderService.findOpen(rabbit.companyId).then(
      (response) => {
        // setObjects(response.data)
        // const evts = response.data.map(item => {
        //   return {
        //     // id: item.id,
        //     title: `${item.customer.surname} ${item.customer.name} [${item.product.serial}]`,
        //     start: item.dateBegin,
        //     end: DateTime.fromISO(item.dateBegin).plus({ days: item.quantity - 1 }).toJSDate(),
        //     editable: false,
        //     extendedProps: item,
        //     className: item.preOrder ? 'info' : 'success'
        //   }
        // })
        const evtsSend = response.data.filter(item =>
          (item.stateId === 1)
        ).map(item => {
          return {
            id: item.id,
            title: `${item.customer.surname} ${item.customer.name} [${item.number}] ${item.extension ? 'ext' : ''}`,
            start: item.dateOrder,
            // end: DateTime.fromISO(item.dateBegin).plus({ days: item.quantity - 1 }).toJSDate(),
            editable: false,
            extendedProps: item,
            className: 'info'
          }
        })
        const evtsSent = response.data.filter(item =>
          (item.stateId === 2)
        ).map(item => {
          return {
            id: item.id,
            title: `${item.customer.surname} ${item.customer.name} [${item.number}]`,
            start: item.dateOrder,
            editable: false,
            extendedProps: item,
            className: 'success'
          }
        })
        const evtsRet = response.data.filter(item =>
          (item.stateId === 3)
        ).map(item => {
          return {
            id: item.id,
            title: `${item.customer.surname} ${item.customer.name} [${item.number}]`,
            // start: item.dateBegin,
            start: DateTime.fromISO(item.dateBegin).plus({ days: item.quantity - 1 }).toISODate(),
            editable: false,
            extendedProps: item,
            className: 'error'
          }
        })
        const evtsBooked = response.data.filter(item =>
          (item.stateId === 50)
        ).map(item => {
          return {
            id: item.id,
            title: `${item.customer.surname} ${item.customer.name} [${item.number}]`,
            // start: item.dateBegin,
            start: DateTime.fromISO(item.dateBegin).plus({ days: item.quantity - 1 }).toISODate(),
            editable: false,
            extendedProps: item,
            className: 'warning'
          }
        })
        // setEvents(evts)
        setEvents([...evtsSend, ...evtsRet, ...evtsSent, ...evtsBooked])
        setToSend(evtsSend)
        setToArrive(evtsSent)
        setToRetrieve(evtsRet)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  const fetchStats = () => {
    orderService.getDashboard(rabbit.companyId).then(
      (response) => {
        setStats(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  const fetchToPay = () => {
    orderService.findToPay(rabbit.companyId).then(
      (response) => {
        setToPay(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  // const onHandleDateClick = (arg) => { // bind with an arrow function
  //   alert(arg.dateStr)
  // }

  const onHandleEventClick = (info) => { // bind with an arrow function
    // info.jsEvent.preventDefault() // se event ha un url
    // alert('Event: ' + info.event.title)
    // alert('id: ' + info.event.id)
    // alert('ext: ' + JSON.stringify(info.event.extendedProps))
    const item = info.event.extendedProps
    navigate(`/customers/${item.customerId}/orders/${item.id}`)
    // url: ,

    // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY)
    // alert('View: ' + info.view.type)

    // change the border color just for fun
    // info.el.style.borderColor = 'red'
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='primary'
                icon='leaderboard'
                title='ordini anno'
                count={stats.yearNum}
                percentage={{
                  color: utils.percentageIncrement(stats.prevYearNum, stats.yearNum) >= 0 ? 'success' : 'error',
                  amount: utils.percentageIncrement(stats.prevYearNum, stats.yearNum) + '%',
                  label: 'rispetto al precedente'
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='primary'
                icon='leaderboard'
                title='ordini mese'
                count={stats.ordersM}
                percentage={{
                  color: utils.percentageIncrement(stats.ordersPrevM, stats.ordersM) >= 0 ? 'success' : 'error',
                  amount: utils.percentageIncrement(stats.ordersPrevM, stats.ordersM) + '%',
                  label: 'rispetto al precedente'
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon='leaderboard'
                title='giorni anno'
                count={stats.year}
                percentage={{
                  color: utils.percentageIncrement(stats.prevYear, stats.year) >= 0 ? 'success' : 'error',
                  amount: utils.percentageIncrement(stats.prevYear, stats.year) + '%',
                  label: 'rispetto al precedente'
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon='leaderboard'
                title='Giorni mese'
                count={stats.month}
                percentage={{
                  color: utils.percentageIncrement(stats.prevMonth, stats.month) >= 0 ? 'success' : 'error',
                  amount: utils.percentageIncrement(stats.prevMonth, stats.month) + '%',
                  label: 'rispetto al precedente'
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='success'
                icon='leaderboard'
                title='euro anno'
                count={utils.formatFloat(stats.euro.toFixed(2))}
                percentage={{
                  color: utils.percentageIncrement(stats.prevEuro, stats.euro) >= 0 ? 'success' : 'error',
                  amount: utils.percentageIncrement(stats.prevEuro, stats.euro) + '%',
                  label: 'rispetto al precedente'
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='success'
                icon='leaderboard'
                title='euro mese'
                count={utils.formatFloat(stats.euroM.toFixed(2))}
                percentage={{
                  color: utils.percentageIncrement(stats.prevEuroM, stats.euroM) >= 0 ? 'success' : 'error',
                  amount: utils.percentageIncrement(stats.prevEuroM, stats.euroM) + '%',
                  label: 'rispetto al precedente'
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} sx={{ height: 'max-content' }}>
            <Card>
              <ZUTitleForm bgColor='info' title='Calendario Ordini' icon='event' />

              {useMemo(
                () => (
                  <EventCalendar
                    initialView='dayGridMonth'
                    dayMaxEventRows={4}
                    // initialDate='2022-02-01'
                    events={events}
                    selectable
                    // editable={false}
                    // dateClick={onHandleDateClick}
                    eventClick={onHandleEventClick}
                  />
                ),
                [events]
              )}
            </Card>
          </Grid>
        </Grid>
        <MDBox mt={6} mb={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%' }}>
                <MDBox pt={2} px={2}>
                  <MDTypography variant='h6' fontWeight='medium'>
                    Prossimi Invii
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  {toSend && toSend.map((item) =>
                    <MDBox key={item.id} mt={3.5}>
                      <DefaultItem
                        color='success'
                        icon='local_shipping'
                        title={item.extendedProps.customer.surname + ' ' + item.extendedProps.customer.name + ' ' + item.extendedProps.customer.businessName}
                        description={'Ord. n. ' + item.extendedProps.number + ' del ' + utils.formatDate(item.extendedProps.dateOrder, 'dd/MM/yyyy')}
                        link={`/customers/${item.extendedProps.customerId}/orders/${item.id}`}
                      />
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%' }}>
                <MDBox pt={2} px={2}>
                  <MDTypography variant='h6' fontWeight='medium'>
                    In consegna
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  {toArrive && toArrive.map((item) =>
                    <MDBox key={item.id} mt={3.5}>
                      <DefaultItem
                        color='primary'
                        icon='local_shipping'
                        title={item.extendedProps.customer.surname + ' ' + item.extendedProps.customer.name + ' ' + item.extendedProps.customer.businessName}
                        description={'Ord. n. ' + item.extendedProps.number + ' del ' + utils.formatDate(item.extendedProps.dateOrder, 'dd/MM/yyyy')}
                        link={`/customers/${item.extendedProps.customerId}/orders/${item.id}`}
                      />
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%' }}>
                <MDBox pt={2} px={2}>
                  <MDTypography variant='h6' fontWeight='medium'>
                    Prossimi Ritiri
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  {toRetrieve && toRetrieve.map((item) =>
                    <MDBox key={item.id} mt={3.5}>
                      <DefaultItem
                        color='error'
                        icon='local_shipping'
                        title={item.extendedProps.customer.surname + ' ' + item.extendedProps.customer.name + ' ' + item.extendedProps.customer.businessName}
                        description={'il ' + utils.formatDate(DateTime.fromISO(item.extendedProps.dateBegin).plus({ days: item.extendedProps.quantity - 1 }), 'dd/MM/yyyy')}
                        link={`/customers/${item.extendedProps.customerId}/orders/${item.id}`}
                      />
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%' }}>
                <MDBox pt={2} px={2}>
                  <MDTypography variant='h6' fontWeight='medium'>
                    In attesa di pagamento
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  {toPay && toPay.map((item) =>
                    <MDBox key={item.id} mt={3.5}>
                      <DefaultItem
                        color='success'
                        icon='payments'
                        title={item.customer.surname + ' ' + item.customer.name + '[ € ' + String(item.toPay.toFixed(2)).replace('.', ',') + ']'}
                        description={'Ord. n. ' + item.number + ' del ' + utils.formatDate(item.dateOrder, 'dd/MM/yyyy')}
                        link={`/customers/${item.customerId}/payments/new?orderId=${item.id}`}
                      />
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={6} mb={6} sx={{ height: 500 }} />
      </MDBox>

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />

      <Footer />
    </DashboardLayout>
  )
}

export default MainDashboard
